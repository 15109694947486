import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import TypesOfInspection from './components/TypesOfInspection';
import Services from './components/Services';
import Book from './components/Book'; // Import the Book component
import Contact from './components/Contact';
import About from './components/About';
import Reviews from './components/Reviews';
import CookiesPolicy from './components/CookiesPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import { AuthProvider } from './context/AuthContext';
import Dashboard from './components/dashboard/Dashboard';
import AddPost from  './components/blog/AddPost';
import BlogList from './components/blog/BlogList';
import BlogPost from './components/blog/BlogPost';
import EditPost from './components/blog/EditPost';
import PreInspect from './components/services/PreInspection';
import RepairInspection from './components/services/RepairInspection';
import PostRepairVerification from './components/services/PostRepairVerification';
import RoadSimulationTesting from './components/services/RoadSimulationTesting';
import VehicleAppraisalsForBanks from './components/services/VehicleAppraisalsForBanks';
import CarInspectionSeoPage from './components/seo/CarInspect';

function App() {
  const location = useLocation();

  return (
    <AuthProvider>
      <div>
        {location.pathname !== '/dashboard' && <Header />}
        <Routes>
          <Route path="/" element={<>
            <Hero />
            <Services />
            <TypesOfInspection />
            <About />
            <Reviews />
          </>} />
          <Route path="/book" element={<Book />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/add-post" element={<AddPost />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/edit-post/:id" element={<EditPost />} />
          <Route path="/services/pre-purchase-inspections" element={<PreInspect />} />
          <Route path="/services/pre-repair-diagnostics" element={<RepairInspection />} />
          <Route path="/services/post-repair-verification" element={<PostRepairVerification />} />
          <Route path="/services/road-simulation-testing" element={<RoadSimulationTesting />} />
          <Route path="/services/vehicle-appraisals-for-banks" element={<VehicleAppraisalsForBanks />} />
          <Route path="/car-inspection-uae" element={<CarInspectionSeoPage />} />
        </Routes>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;