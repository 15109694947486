import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdLogIn } from "react-icons/io";
import { HiMenu, HiX } from "react-icons/hi";
import { useNavigate, Link } from 'react-router-dom';
import profLogo from '../assets/prof-logo.png';

const Header = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isRTL, setIsRTL] = useState(i18n.language === 'ar');

  useEffect(() => {
    setIsRTL(i18n.language === 'ar');
    document.body.dir = isRTL ? 'rtl' : 'ltr';
  }, [i18n.language, isRTL]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigate = useNavigate();

  const handleServicesClick = () => {
    navigate('/');
    setTimeout(() => {
      const section = document.getElementById('services-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <header className={isRTL ? 'rtl' : 'ltr'}>
      <div className="w-full z-50 absolute">
        <nav className="flex justify-between items-center max-w-[1440px] mx-auto sm:px-16 px-6 py-4 bg-transparent">
          <Link to='/' className="flex justify-center items-center">
            <img src={profLogo} alt="Al-Professor Logo" className="h-10 w-auto" />
          </Link>

          <div className={`hidden md:flex ${isRTL ? 'space-x-reverse space-x-8' : 'space-x-8'}`}>
            <Link to='/' className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('home')}
            </Link>
            <span onClick={handleServicesClick} className="text-lg font-medium text-gray-700 hover:text-black transition-colors cursor-pointer">
              {t('services')}
            </span>
            <Link to='/book' className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('book')}
            </Link>
            <Link to='/blog' className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('blog')}
            </Link>
            <a href='https://www.google.com/search?sca_esv=e7a2bf937769b224&hl=en-AE&gl=ae&sxsrf=ADLYWILKY0KhxGgcPYb5iPOCjFEh5c9RXg:1724579553317&q=%D9%81%D8%AD%D8%B5+%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA+%D8%A8%D8%B1%D9%88%D9%81%D9%8A%D8%B3%D9%88%D8%B1+reviews&uds=ADvngMgv4GhERk0sppt-o9GYOZU48v2nGMXo40oPqmO05ASAEmtRyZTVERIscUpkmsLm13n1mWGcPPFJvS9-Qy1A2p4HCm6xI1GiSaU4I1BAxxldgV-du9e71J2WiU9qU3CoohykD_ehLEiT02csNNREuwxpGYFY6-FQvW3wuwHVx1Ae5W2BOE0I3YMMAIWL5FB3Hs1JG9XpDBLp8SiMkA27yd5qdbEDy00Nd5k2ABgYFv9pXmdVgXBNsxrJwgv0t6KafYdYAXdfMdhQSbTnKouLhTWZG7OHyuKVu5MzVKgnK1DYwuYvkp1K7N4qtO07ygNuGnr3YLcm2AlR0Ww3VEp7y2xDEjMfyDKhLeVJQnBHmQokyTFQxkDXmbQAR_NRBtpicvxvtjoyHEEPeft-yDP0kGMziYHZK0MxPGIsbrlhdRDHRlyTHW9Tdwb5FGQXwD2L8nxXsqJ9ZFchUqhHO8jjd0fxmPXxAg&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7qhmEC5Z7Q1YL7l8lI7RyUiR0BJDQ5EDS83k_RKPl8M1-J16kPiqIY19qt8eWa-GAHVe-Drp9SYVAGsdltYFZ3TOvRYqyCdrPVoOL0a8-_O3oClAhwCHkDxxDYIPJXeDcrT2LmA%3D&sa=X&ictx=1&lei=4f7KZojwEoiRxc8P3OyquA0' className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('review_us')}
            </a>
            <a href="tel:00971561868236" className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('contact')}
            </a>
          </div>

          <div className={`md:hidden flex items-center ${isRTL ? 'mr-auto' : 'ml-auto'}`}>
            <button onClick={toggleMenu} className="text-2xl text-black focus:outline-none">
              {menuOpen ? <HiX /> : <HiMenu />}
            </button>
          </div>

          <div className={`hidden md:flex logedin ${isRTL ? 'space-x-reverse space-x-4' : 'space-x-4'}`}>
            <button onClick={() => switchLanguage('en')}>EN</button>
            <button onClick={() => switchLanguage('ar')}>AR</button>
            <a href='/login'>
              <IoMdLogIn />
            </a>
          </div>
        </nav>

        {/* Mobile Menu */}
        {menuOpen && (
          <div className={`md:hidden flex flex-col ${isRTL ? 'items-end text-right' : 'items-start text-left'} space-y-2 p-4 bg-gray-50 rounded-lg shadow-lg`}>
            {[
              { to: '/', text: t('home') },
              { onClick: handleServicesClick, text: t('services') },
              { to: '/book', text: t('book') },
              { to: '/blog', text: t('blog') },
              { to: "https://www.google.com/search?sca_esv=e7a2bf937769b224&hl=en-AE&gl=ae&sxsrf=ADLYWILKY0KhxGgcPYb5iPOCjFEh5c9RXg:1724579553317&q=%D9%81%D8%AD%D8%B5+%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA+%D8%A8%D8%B1%D9%88%D9%81%D9%8A%D8%B3%D9%88%D8%B1+reviews&uds=ADvngMgv4GhERk0sppt-o9GYOZU48v2nGMXo40oPqmO05ASAEmtRyZTVERIscUpkmsLm13n1mWGcPPFJvS9-Qy1A2p4HCm6xI1GiSaU4I1BAxxldgV-du9e71J2WiU9qU3CoohykD_ehLEiT02csNNREuwxpGYFY6-FQvW3wuwHVx1Ae5W2BOE0I3YMMAIWL5FB3Hs1JG9XpDBLp8SiMkA27yd5qdbEDy00Nd5k2ABgYFv9pXmdVgXBNsxrJwgv0t6KafYdYAXdfMdhQSbTnKouLhTWZG7OHyuKVu5MzVKgnK1DYwuYvkp1K7N4qtO07ygNuGnr3YLcm2AlR0Ww3VEp7y2xDEjMfyDKhLeVJQnBHmQokyTFQxkDXmbQAR_NRBtpicvxvtjoyHEEPeft-yDP0kGMziYHZK0MxPGIsbrlhdRDHRlyTHW9Tdwb5FGQXwD2L8nxXsqJ9ZFchUqhHO8jjd0fxmPXxAg&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7qhmEC5Z7Q1YL7l8lI7RyUiR0BJDQ5EDS83k_RKPl8M1-J16kPiqIY19qt8eWa-GAHVe-Drp9SYVAGsdltYFZ3TOvRYqyCdrPVoOL0a8-_O3oClAhwCHkDxxDYIPJXeDcrT2LmA%3D&sa=X&ictx=1&lei=4f7KZojwEoiRxc8P3OyquA0", text: t('review_us') },
              { to: "tel:00971561868236", text: t('contact') },
            ].map((item, index) => (
              <Link
                key={index}
                to={item.to}
                href={item.href}
                className="w-full px-4 py-2 text-lg font-medium text-gray-700 hover:text-black transition-colors bg-white rounded-md shadow-sm hover:shadow-md"
                onClick={item.onClick || toggleMenu}
              >
                {item.text}
              </Link>
            ))}

            <div className={`flex ${isRTL ? 'space-x-reverse space-x-2' : 'space-x-2'} w-full ${isRTL ? 'justify-end' : 'justify-start'} mt-4`}>
              <button onClick={() => switchLanguage('en')} className="px-3 py-1 bg-white rounded shadow-sm hover:shadow-md transition-shadow">EN</button>
              <button onClick={() => switchLanguage('ar')} className="px-3 py-1 bg-white rounded shadow-sm hover:shadow-md transition-shadow">AR</button>
            </div>

            <div className={`flex logedin w-full ${isRTL ? 'justify-end' : 'justify-start'} mt-4`}>
              <a href='/login' onClick={toggleMenu} className="p-2 bg-white rounded-full shadow-sm hover:shadow-md transition-shadow">
                <IoMdLogIn size={24} />
              </a>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;