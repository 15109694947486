import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import CustomButton from "./CustomButton";
import { TextGenerateEffect } from "../ui/TextGenerationEffect";
import heroImg from '../assets/car-inspected.png';

const Hero = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';

    return (
        <div className={`flex flex-col lg:flex-row gap-5 relative z-0 max-w-[1440px] mx-auto ${isRTL ? 'lg:flex-row-reverse' : ''}`}>
            <Helmet>
                <title>{t('hero_metaTitle')}</title>
                <meta name="description" content={t('hero_metaDescription')} />
                <meta name="keywords" content="Car inspection, Vehicle inspection, Dubai, Abu Dhabi, UAE, Car maintenance, Vehicle safety, Auto services, Technical inspection" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <div className={`flex-1 pt-36 px-6 sm:px-16 ${isRTL ? 'text-right' : 'text-left'}`}>
                <TextGenerateEffect
                    words={t('hero_title')}
                    className="font-extrabold"
                />
                <p className="font-light text-[27px] mt-5 text-black-100">
                    {t('hero_description')}
                </p>
                <div className={`flex gap-4 mt-10 ${isRTL ? 'flex-row-reverse' : ''}`}>
                    <a href="https://maps.app.goo.gl/pCJaz6oGmEgim6gk6" target="_blank" rel="noopener noreferrer">
                        <CustomButton 
                            title={t('visit_us')}
                            buttonStyle="bg-primary-blue text-white rounded-full hover:bg-blue-700 transition-colors duration-300" 
                        />
                    </a>
                    <a href="https://wa.me/00971561868236?text=I'd%20like%20to%20book%20an%20inspection%20for%20my%20vehicle." target="_blank" rel="noopener noreferrer">
                        <CustomButton 
                            title={t('book_inspection')}
                            buttonStyle="bg-cyan-500 text-white rounded-full hover:bg-cyan-600 transition-colors duration-300 shadow-md" 
                        />
                    </a>
                </div>
            </div>

            <div className="flex xl:flex-[1.5] justify-end items-end xl:h-screen w-full">
                <div className="relative z-0 xl:w-full w-[90%] h-[590px] xl:h-full">
                    <img src={heroImg} alt={t('hero_imageAlt')} className="object-contain w-full h-full" />
                </div>
                <div className="bg-hero-bg absolute xl:-top-24 xl:-right-1/2 -right-1/4 bg-repeat-round -z-10 w-full xl:h-screen h-[590px] overflow-hidden" />
            </div>
        </div>
    );
}

export default Hero;
