import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import inspectionImg from '../../assets/car-inspected.png';

const CarInspection = () => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto p-6 pt-24">
            <Helmet>
                <title>{t('carInspection.title')}</title>
                <meta name="description" content={t('carInspection.metaDescription')} />
                <meta name="keywords" content="Car inspection, Vehicle inspection, Dubai, Abu Dhabi, Technical inspection, Safety check, UAE, Roadworthiness, Auto inspection, Vehicle safety, Car maintenance" />
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="index, follow" />
                <html lang={t('lang')} dir={t('dir')} />
            </Helmet>

            <motion.div 
                initial={{ opacity: 0, y: 20 }} 
                animate={{ opacity: 1, y: 0 }} 
                transition={{ duration: 0.5 }} 
                className="text-center"
            >
                <h1 className="text-4xl font-bold text-gray-800 mb-6">{t('carInspection.heading')}</h1>
                <p className="text-lg text-gray-600 mb-6">
                    {t('carInspection.subHeading')}
                </p>
                <img src={inspectionImg} alt={t('carInspection.imageAlt')} className="w-full h-auto max-w-lg mx-auto rounded-lg shadow-md" />
            </motion.div>

            <div className="mt-12 bg-gray-100 p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800">{t('carInspection.servicesTitle')}</h2>
                <p className="text-lg text-gray-700 mt-4">
                    {t('carInspection.servicesIntro')}
                </p>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>{t('carInspection.service1')}</li>
                    <li>{t('carInspection.service2')}</li>
                    <li>{t('carInspection.service3')}</li>
                    <li>{t('carInspection.service4')}</li>
                    <li>{t('carInspection.service5')}</li>
                    <li>{t('carInspection.service6')}</li>
                </ul>
            </div>

            <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold text-gray-800">{t('carInspection.benefitsTitle')}</h3>
                <p className="text-lg text-gray-700 mt-4">
                    {t('carInspection.benefitsIntro')}
                </p>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>{t('carInspection.benefit1')}</li>
                    <li>{t('carInspection.benefit2')}</li>
                    <li>{t('carInspection.benefit3')}</li>
                    <li>{t('carInspection.benefit4')}</li>
                    <li>{t('carInspection.benefit5')}</li>
                </ul>
            </div>

            <div className="mt-8 bg-gray-100 p-6 rounded-lg">
                <h3 className="text-2xl font-semibold text-gray-800">{t('carInspection.whyUsTitle')}</h3>
                <p className="text-lg text-gray-700 mt-4">
                    {t('carInspection.whyUsIntro')}
                </p>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>{t('carInspection.whyUs1')}</li>
                    <li>{t('carInspection.whyUs2')}</li>
                    <li>{t('carInspection.whyUs3')}</li>
                    <li>{t('carInspection.whyUs4')}</li>
                </ul>
            </div>

            <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold text-gray-800">{t('carInspection.moreInfoTitle')}</h3>
                <p className="text-lg text-gray-700 mt-4">
                    {t('carInspection.moreInfoContent')}
                </p>
                <p className="text-lg text-gray-700 mt-4">
                    {t('carInspection.moreInfoContent2')}
                </p>
                <p className="text-lg text-gray-700 mt-4">
                    {t('carInspection.moreInfoContent3')}
                </p>
            </div>

            <div className="mt-8 text-center">
                <a href="/book" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {t('carInspection.cta')}
                </a>
            </div>
            <div className="mt-8 text-center ">
                <a href="/contact" className="bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded">
                    {t('carInspection.contactUs')}
                </a>
                </div>
  
        </div>
    );
};

export default CarInspection;
