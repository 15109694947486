import React, { useState } from "react";
import { TextGenerateEffect } from "../ui/TextGenerationEffect";
import { useTranslation } from "react-i18next";
import { MapPin } from "lucide-react";

const Book = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    carBrand: "",
    carModel: "",
    testType: "",
    preferredDate: "",
    preferredTime: "",
    testLocation: "center",
    customerLocation: "",
  });
  const [coordinates, setCoordinates] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleShareLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude });
          setFormData({
            ...formData,
            customerLocation: `https://www.google.com/maps?q=${latitude},${longitude}`,
          });
        },
        (error) => {
          console.error("Error getting location:", error);
          alert(t('locationError'));
        }
      );
    } else {
      alert(t('geolocationNotSupported'));
    }
  };

  const handleConfirm = () => {
    const { fullName, phoneNumber, carBrand, carModel, testType, preferredDate, preferredTime, testLocation, customerLocation } = formData;

    let message = `${t('bookingDetails')}

${t('name')}: ${fullName}
${t('phone')}: ${phoneNumber}

${t('carBrand')}: ${carBrand}
${t('carModel')}: ${carModel}

${t('testType')}: ${testType}

${t('preferredDate')}: ${preferredDate}
${t('preferredTime')}: ${preferredTime}
${t('testLocation')}: ${testLocation}`;

    if (testLocation === "customer") {
      message += `\n${t('customerLocation')}: ${customerLocation}`;
    }

    const encodedMessage = encodeURIComponent(message);
    window.location.href = `https://wa.me/00971561868236?text=${encodedMessage}`;
  };

  return (
    <div className={`container mx-auto p-4 max-w-lg ${isRTL ? 'text-right' : 'text-left'}`}>
      <div className="text-center mt-10">
        <TextGenerateEffect words={t("bookYourTest")} className="text-xl md:text-2xl font-semibold" />
      </div>
      <div className="space-y-4 mt-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('fullName')}</label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            placeholder={t('enterYourFullName')}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t('phoneNumber')}</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            placeholder={t('enterYourPhoneNumber')}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t('carBrand')}</label>
          <input
            type="text"
            name="carBrand"
            value={formData.carBrand}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            placeholder={t('enterYourCarBrand')}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t('carModel')}</label>
          <input
            type="text"
            name="carModel"
            value={formData.carModel}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            placeholder={t('enterYourCarModel')}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t('typeOfTest')}</label>
          <select
            name="testType"
            value={formData.testType}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          >
            <option value="">{t('selectTestType')}</option>
            <option value="Pre-purchase Inspection">{t('prePurchaseInspection')}</option>
            <option value="Pre-repair Diagnostics">{t('preRepairDiagnostics')}</option>
            <option value="Post-repair Verification">{t('postRepairVerification')}</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t('preferredDate')}</label>
          <input
            type="date"
            name="preferredDate"
            value={formData.preferredDate}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t('preferredTime')}</label>
          <input
            type="time"
            name="preferredTime"
            value={formData.preferredTime}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t('locationOfTest')}</label>
          <select
            name="testLocation"
            value={formData.testLocation}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          >
            <option value="center">{t('atCenter')}</option>
            <option value="customer">{t('atYourLocation')}</option>
          </select>
        </div>

        {formData.testLocation === "customer" && (
          <div>
            <label className="block text-sm font-medium text-gray-700">{t('customerLocation')}</label>
            <div className="flex mt-1">
              <input
                type="text"
                name="customerLocation"
                value={formData.customerLocation}
                onChange={handleChange}
                className="block w-full border border-gray-300 rounded-l-md p-2"
                placeholder={t('enterYourLocation')}
              />
              <button
                onClick={handleShareLocation}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-md flex items-center"
              >
                <MapPin className="mr-2" size={16} />
                {t('shareLocation')}
              </button>
            </div>
          </div>
        )}

        <button
          onClick={handleConfirm}
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6"
        >
          {t('confirmBooking')}
        </button>
      </div>
    </div>
  );
};

export default Book;
